import { default as _91_46_46_46slug_93tMvA94XWEsMeta } from "/home/runner/work/dunder-website/dunder-website/pages/[...slug].vue?macro=true";
import { default as blog_45pageTrbQlO2qtRMeta } from "/home/runner/work/dunder-website/dunder-website/pages/blog-page.vue?macro=true";
import { default as contacthvN2dKORL2Meta } from "/home/runner/work/dunder-website/dunder-website/pages/contact.vue?macro=true";
import { default as coursesb91OMxfV1cMeta } from "/home/runner/work/dunder-website/dunder-website/pages/courses.vue?macro=true";
import { default as indextdYwI3u0EiMeta } from "/home/runner/work/dunder-website/dunder-website/pages/index.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/home/runner/work/dunder-website/dunder-website/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-page",
    path: "/blog-page",
    component: () => import("/home/runner/work/dunder-website/dunder-website/pages/blog-page.vue").then(m => m.default || m)
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/home/runner/work/dunder-website/dunder-website/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "courses",
    path: "/courses",
    component: () => import("/home/runner/work/dunder-website/dunder-website/pages/courses.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/dunder-website/dunder-website/pages/index.vue").then(m => m.default || m)
  }
]